import { graphql, Link } from 'gatsby'
import React from 'react'
import SEO from 'react-seo-component'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { SinglePostLayout } from '../components/Layouts/SinglePostLayout'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

export default ({ data, pageContext }) => {
  const {
    description,
    title,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata()

  const { next, previous, currentPage, numPages } = pageContext

  return (
    <SinglePostLayout>
      <SEO
        title={'articles'}
        titleTemplate={title}
        description={description || 'nothing'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <IndexWrapper>
        {data.allPosts.nodes.map(
          ({ id, frontmatter, fields }) => (
            <PostWrapper key={id}>
              <Link to={fields.slug}>
                <Date>{frontmatter.date}</Date>
                
                <ArticleWrapper>
                  <Cover>
                    {frontmatter.cover ? 
                      (
                        <Image
                          sizes={frontmatter.cover.childImageSharp.sizes}
                          alt={frontmatter.coverName}
                        />
                      ) : null
                    } 
                  </Cover> 
                  <Content>
                    <Category>{frontmatter.category}</Category>
                    <Title>{frontmatter.title}</Title>
                  </Content>
                </ArticleWrapper>
              </Link>
            </PostWrapper>
          )
        )}
      </IndexWrapper>
      
      <PaginationWrapper>
        {(previous === null && previous !== '') ? 
          <StyledPaginationLink hide={'true'} to={`/articles/${previous}`}>&lt; Previous</StyledPaginationLink> :
          <StyledPaginationLink to={`/articles/${previous}`}>&lt; Previous</StyledPaginationLink>
        }
        
          <span>{currentPage} / {numPages}</span>
        {next ? 
          <StyledPaginationLink green={'true'} to={`/articles/${next}`}>Next &gt;</StyledPaginationLink> :
          <StyledPaginationLink hide={'true'} to={`/articles/${next}`}>Next &gt;</StyledPaginationLink>
        }
        
      </PaginationWrapper>
      
    </ SinglePostLayout>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.2rem;
  font-family: 'Bangers','cursive';
  text-align: center;
  padding: 1rem;
  background: ${props => props.theme.green};  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, white, white, ${props => props.theme.green}, white, white, ${props => props.theme.red}, white, white);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, white, white, ${props => props.theme.green}, white, white, ${props => props.theme.red}, white, white); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: .3rem;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  
  a {
    text-decoration: none;
    color: black;
  }

  * {
    padding: .5rem;

    width: 33%;
  }

  @media only screen and (max-width: 1120px) {
    background: white;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1rem;
  }
`

const StyledPaginationLink = styled(Link)`

  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  ${props => props.hide === 'true' ? `
    pointer-events: none;
    cursor: default;
    opacity: 0;
  ` : null};

  &:hover {
    ${props => props.green === 'true' ? `
      color: ${props.theme.green}
    ` : `
      color: ${props.theme.red}
    `
    }
  }
`

const Date = styled.div`
  margin: 1rem 1rem;
  color: black;
  font-size: .8rem;

  @media only screen and (max-width: 800px) {
    font-size: .7rem;
    margin: .3rem 1rem;
  }

  @media only screen and (max-width: 400px) {
    margin: .8rem 1rem;
  }
`

const ArticleWrapper = styled.div`
  display: flex;
`
const Cover = styled.div`
  width: 25%;
  margin: 1rem;

  @media only screen and (max-width: 1120px) {
    width: 30%;
  }

  @media only screen and (max-width: 500px) {
    width: 40%;
  }
`
const Content = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1120px) { 
    width: 70%;
  }  

  @media only screen and (max-width: 500px) {
    width: 60%;
  }
`
const Category = styled.h2`
  text-transform: uppercase;
  font-family: 'Josefin Sans', 'sans-serif';
  font-weight: 700;
  font-size: 1.5rem;
  color: black;

  @media only screen and (max-width: 800px) {
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 680px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: .8rem;
  }

  @media only screen and (max-width: 300px) {
    font-size: .7rem;
  }
`

const Title = styled.h1`
  font-family: 'Oswald', 'sans-serif';
  color: black;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.5px;

  @media only screen and (max-width: 1120px) {
    font-size: 35px;
    line-height: 45px;
  }

  @media only screen and (max-width: 800px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media only screen and (max-width: 680px) {
    font-size: 25px;
    line-height: 35px;
  }

  @media only screen and (max-width: 680px) {
    font-size: 25px;
    line-height: 35px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media only screen and (max-width: 350px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const IndexWrapper = styled.main`
  
  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    padding: 15px;
  }
`

const PostWrapper = styled.div`
  margin: 1rem auto;
`

const Image = styled(Img)`
  border-radius: 5px;
`
export const query = graphql`
  query ArticlesQuery($limit: Int!, $skip: Int!) {
    allPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } },
      limit: $limit,
      skip: $skip
    ) {
      nodes {
        id
        excerpt(pruneLength: 100)
        frontmatter {
          title
          writtenBy
          category
          date(fromNow: true)
          cover {
            publicURL
            childImageSharp {
              sizes(maxWidth: 2000, maxHeight: 1000, traceSVG: { color: "#0f9449" }) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
          coverName
        }

        fields {
          slug
        }
      }
    }
  }
`